import { Box, Tooltip } from '@mui/material';
import CustomIcon from 'common/CustomIcon';
import { ICON_TYPE } from 'common/interfaces/enums';
import type {
  ActionController,
  DatasetSchema,
  SampleData
} from 'common/interfaces/interfaces';
import { transformFeatureStateAtom } from 'featureEngineering/atoms/atomTransformFeature';
import { type ModifiedVariables } from 'featureEngineering/featureEngineeringInterface';
import { getPreviewModified } from 'featureEngineering/utils/transformationUtils';
import type { ContextData } from 'mySpace/uploadManager/UploadManager';
import { FeatureType } from 'playground/interfaces/playground';
import { memo, useEffect } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { contextSelectionAtom } from 'mySpace/uploadManager/atoms/atomContextSelections';
import { type TableCellProps } from 'common/table/CustomTableCell';
import CustomTable, {
  type TableRowProps,
  type HeaderRowProps
} from 'common/table/CustomTable';

import 'common/Common.scss';
import './previewDataset.scss';

//  TODO: create a separate component for the tooltip
const CustomTooltip = (
  <Tooltip
    title={
      <Box className="tooltip" sx={{ width: '175px', padding: '6px' }}>
        <p>There are 6 types for data:</p>
        <p>
          -<u>ID</u> : unique values (required one ID column per dataset)
        </p>
        <p>
          -<u>Categorical</u> : set amount of values that can be categorised{' '}
        </p>
        <p>
          -<u>Binary</u> : categorical values with only two variables{' '}
        </p>
        <p>
          -<u>Numerical</u> : only numbers, cannot be categorise{' '}
        </p>
        <p>
          -<u>Textual</u> : text or text and numbers which cannot be categorise{' '}
        </p>
        <p>
          -<u>Date</u> : date values which could come in different formats
        </p>
      </Box>
    }
    placement="bottom"
    arrow
  >
    <div>
      <CustomIcon
        className="dark-icon-no-hover"
        style={{
          justifyContent: 'center',
          paddingLeft: '5px',
          width: '15px'
        }}
        type={ICON_TYPE.INFO_CIRCLE}
      />
    </div>
  </Tooltip>
);

const CustomOutputTooltip = (
  <Tooltip
    title={
      <Box className="tooltip" sx={{ width: '175px', padding: '6px' }}>
        Mark the columns that represent your goals so our platform can analyse
        them and provide the most accurate results.
      </Box>
    }
    placement="bottom"
    arrow
  >
    <div>
      <CustomIcon
        className="grey-icon-no-hover"
        style={{
          justifyContent: 'center',
          paddingLeft: '5px',
          width: '15px'
        }}
        type={ICON_TYPE.INFO_CIRCLE}
      />
    </div>
  </Tooltip>
);

const CustomContextTooltip = (
  <Tooltip
    title={
      <Box className="tooltip" sx={{ width: '175px', padding: '6px' }}>
        <p>
          Adding context to dataset columns improves accuracy and insights while
          helping the platform guide you.
        </p>
        <br />
        <p>Context is required for output features to be analysed.</p>
      </Box>
    }
    placement="bottom"
    arrow
  >
    <div>
      <CustomIcon
        className="grey-icon-no-hover"
        style={{
          justifyContent: 'center',
          paddingLeft: '5px',
          width: '15px'
        }}
        type={ICON_TYPE.INFO_CIRCLE}
      />
    </div>
  </Tooltip>
);

interface PreviewDatasetProps {
  displayTableData: DatasetSchema[];
  options?: {
    title?: string;
    clickAction?: ActionController['action'];
    selectedRow?: number;
    createContext?: React.MutableRefObject<ContextData>;
  };
}

const PreviewDataset = memo(function PreviewDataset({
  displayTableData,
  options = {}
}: PreviewDatasetProps) {
  const transformationState = useRecoilValue(transformFeatureStateAtom);
  const [contextSelection, setContextSelection] =
    useRecoilState(contextSelectionAtom);

  useEffect(() => {
    if (options.createContext !== undefined) {
      const mappedContext = options.createContext.current.map(
        (selectedContext, ind) => {
          // we need to change the value directly to it's reference to change the value of the input
          selectedContext.value =
            contextSelection[ind] !== undefined
              ? contextSelection[ind].description
              : selectedContext.value;
          const checked = contextSelection[ind]?.checked ?? false;
          return {
            name: displayTableData[ind].name,
            description: selectedContext.value,
            checked
          };
        }
      );
      setContextSelection(mappedContext);
    }
  }, [options.createContext]);

  const getPreviewClasses = (
    modifiedData: ModifiedVariables,
    index: number
  ): string => {
    let { disregard, edited, added } = modifiedData;

    disregard = Boolean(disregard);
    edited = Boolean(edited);
    added = Boolean(added);

    if (disregard && (Boolean(edited) || added)) {
      edited = false;
      added = false;
    } else if (edited) {
      added = false;
    }

    const isPreview = options.clickAction !== undefined ? 'preview-hover' : '';
    return `${options.selectedRow === index ? 'select' : isPreview}
      ${disregard ? 'disregard' : ''}
      ${edited ? 'preview-edited' : ''}
      ${added ? 'preview-added' : ''}`;
  };

  const tableHeadRows: HeaderRowProps = {
    classes: 'preview-table-row',
    cells: [
      {
        jsxrest: 'Column'
      },
      {
        jsxrest: (
          <Box key={'preview-table-type'} className="tooltipBox">
            Type {CustomTooltip}
          </Box>
        )
      },
      { jsxrest: 'Sample data' }
    ]
  };
  if (
    options.createContext !== undefined &&
    options.clickAction !== undefined
  ) {
    tableHeadRows.cells.push(
      {
        jsxrest: (
          <Box key={'preview-table-output'} className="tooltipBox">
            Output {CustomOutputTooltip}
          </Box>
        )
      },
      {
        options: { classes: { class: 'context-input-header' } },
        jsxrest: (
          <Box key={'preview-table-context'} className="tooltipBox">
            Context {CustomContextTooltip}
          </Box>
        )
      }
    );
  }

  const tableBodyRows: TableRowProps[] = displayTableData.map((data, index) => {
    const renderModifyedVariables = getPreviewModified(
      transformationState,
      data.name
    );
    let contextOfDisplayedData: string | undefined;
    let sampleData = data.sampleData;
    if (
      data.dataValue === FeatureType.NUMERICAL ||
      data.dataValue === FeatureType.DATETIME
    ) {
      contextOfDisplayedData = 'min_max';
      if (
        typeof data.sampleData === 'object' &&
        'min' in data.sampleData &&
        'max' in data.sampleData
      ) {
        sampleData = `${data.sampleData.min}-${data.sampleData.max}`;
      }
    }
    const isDefined = contextSelection[index] !== undefined;
    let contextViewClasses = '';
    if (
      options.createContext !== undefined &&
      options.clickAction !== undefined
    ) {
      if (isDefined) {
        contextViewClasses = 'context-warning';
        if (
          contextSelection[index].checked &&
          contextSelection[index].description === ''
        ) {
          contextViewClasses = 'context-error';
        } else if (
          contextSelection[index].checked ||
          (!contextSelection[index].checked &&
            contextSelection[index].description?.trim() !== '')
        ) {
          contextViewClasses = 'context-green';
        }
      }
    }
    const cells: TableCellProps[] = [
      {
        tdData: data.name,
        index,
        options: {
          withChip: false,
          limitVisibility: false,
          tipTitle: true
        }
      },
      { tdData: data.dataValue, index },
      {
        tdData: sampleData as SampleData,
        index,
        options: {
          withChip: true,
          contextOfDisplayedData,
          tipTitle: true,
          limitVisibility: options.createContext !== undefined
        }
      }
    ];

    if (
      options.createContext !== undefined &&
      options.clickAction !== undefined
    ) {
      cells.push(
        {
          index,
          options: {
            classes: { class: 'context-td context-checkbox' },
            actionController: {
              action: options.clickAction,
              values: { index, value: data.name }
            },
            checked: isDefined ? contextSelection[index].checked : false
          }
        },
        {
          index,
          options: { classes: { class: `context-td ${contextViewClasses}` } },
          jsxrest: (
            <textarea
              className="context-input"
              style={{
                overflow: 'hidden',
                resize: 'none',
                width: '100%',
                height: '48px',
                minHeight: '48px',
                boxSizing: 'border-box'
              }}
              ref={(el: HTMLTextAreaElement) => {
                if (options.createContext?.current !== undefined) {
                  options.createContext.current[index] = el;
                }
              }}
              onChange={(event) => {
                if (options.createContext?.current[index] !== undefined) {
                  options.createContext.current[index].value =
                    event.target.value;
                  event.target.style.height = '48px';
                  // Initial set of height to 48px when deleting all text;
                  event.target.style.height = `${event.target.scrollHeight}px`;
                }
              }}
              onBlur={() => {
                if (options.createContext?.current[index] !== undefined) {
                  setContextSelection([
                    ...contextSelection.slice(0, index),
                    {
                      ...contextSelection[index],
                      description: options.createContext.current[index].value
                    },
                    ...contextSelection.slice(index + 1)
                  ]);
                }
              }}
            />
          )
        }
      );
    }

    const classes = getPreviewClasses(renderModifyedVariables, index);
    const rowData: TableRowProps = {
      cells,
      classes: `preview-table-row ft-sm ${classes}`
    };

    if (
      options.selectedRow !== index &&
      options.clickAction !== undefined &&
      options.createContext === undefined
    ) {
      rowData.actionController = {
        action: options.clickAction,
        values: { index, value: data.name }
      };
    }

    return rowData;
  });

  return (
    <Box style={{ display: 'contents' }}>
      {options.title !== undefined ? (
        <h1 className="table-label">{options.title}</h1>
      ) : null}
      <div className="wrapp-preview-table">
        <CustomTable
          className="preview-table"
          header={tableHeadRows}
          body={tableBodyRows}
          disableHover={
            options.clickAction === undefined ||
            options.createContext !== undefined
          }
        />
      </div>
    </Box>
  );
});

export default PreviewDataset;
